<template>
  <v-card :loading="loading" class="mx-auto" style="height: 100vh;">
    <div class="background-img" :style="{ backgroundImage: `url(${backgroundSrc})` }"></div>

    <div class="controls" v-if="shouldShowParametersButton">
      <button class="param-button" @click="showModelParameters">查看参数</button>
    </div>
    
    <div id="oneModel" class="zoomable-container" @touchstart="handleTouchStart" @touchmove="handleTouchMove" @touchend="handleTouchEnd">
      <transition name="zoom">
        <img :src=pictureSrc :style="{position: 'absolute', width: 'calc(100%)', height: 'auto', bottom: 'calc(12%)', margin: 'auto', transform: `scale(${scaleFactor})`, left: `${imagePositionX}px`, top: `${imagePositionY}px`}"/>
      </transition>
    </div>

    <div v-if="showModelParametersContainer" class="model-parameters-container">
      <div class="close-button" @click="closeModelParameters"></div>
      <img :src="modelInfoSrc"/>
    </div>
    <div>
        <span style="
      position: absolute;
      bottom: 0px;
      font-size: 12px;
      left: 3px;
  ">沪ICP备10002431号
      </span>
      <span style="
          bottom: 0px;
          position: absolute;
          right: 3px;
          font-size: 12px;
      ">沪公网安备31010502000766号
      </span>
    </div>
  </v-card>
</template>

<script>
import api from "../../api/api";
export default {
  name: "TwoModel",
  data: () => ({
    loading: false,
    title: 'test',
    currentModelIndex: 0,
    currentModelName: '',
    subSysname: '',
    backgroundSrc: '',
    pictureSrc: '',
    scaleFactor: 1.0,
    showModelParametersContainer: false,
    modelInfoSrc: '', // 默认图片的URL
    touchStartDistance: 0,
    touchEndDistance: 0,
    touchTimer: null,
    threshold: 10,
    touchStartX: 0,
    touchStartY: 0,
    imagePositionX: 0,
    imagePositionY: 0,
    modelNameCollection: ['空调鼓风机GBB', '空调鼓风机GBM', "电动压缩机800V", "电子冷却风扇FED2", "电子冷却风扇ECM2", "电子水泵PCE2", "电子水泵PCD2-FL", "标准前雨刮驱动模块WSM3-FL", "标准前雨刮驱动模块WSD4S", "电子前雨刮驱动模块WSD4-FL", "电子前雨刮驱动模块WDD2", "电子前雨刮驱动模块WDD3", "后雨刮系统ADO5"],
    shouldShowParametersButton: false,
    isPinching: false
  }),
  computed: {
    containerStyle() {
      return {
        position: 'absolute',
        left: 'calc(5%)',
        right: 'calc(5%)',
        bottom: 'calc(45%)',
        margin: 'auto'
      };
    }
  },
  mounted() {
    let subSysname = this.$route.query.subSysname || "";
    let modelName = this.$route.query.name || "";
    this.currentModelName = modelName;
    this.threeModels = api.hanbook.getThreeModelData(subSysname?subSysname:"");
    this.currentModelIndex = this.threeModels.findIndex(function(item, i){
      return item.name === modelName
    });
    this.subSysname = subSysname;

    const bgSrc = this.threeModels[this.currentModelIndex].imgsrc;
    const picSrc = this.threeModels[this.currentModelIndex].modelPath;
    const miSrc = this.threeModels[this.currentModelIndex].infoPath;
    if(bgSrc && bgSrc !=""){
      this.backgroundSrc = bgSrc;
    }
    if(picSrc && picSrc !=""){
      this.pictureSrc = picSrc;
    }
    if(miSrc && miSrc !=""){
      this.modelInfoSrc = miSrc;
    }
    if (this.modelNameCollection.includes(this.currentModelName)) {
      this.shouldShowParametersButton = true;
    }
  },

  beforeDestroy() {
    // 在组件销毁前移除滚轮事件监听
    window.removeEventListener('wheel', this.handleMouseWheel);
  },
  methods: {
    handleTouchStart(event) {
      if (event.touches.length === 2) {
        this.isPinching = true;
        this.touchStartDistance = Math.hypot(
          event.touches[0].clientX - event.touches[1].clientX,
          event.touches[0].clientY - event.touches[1].clientY
        );
      } else if (event.touches.length === 1) {
        this.isTouching = true;
        this.touchStartX = event.touches[0].clientX;
        this.touchStartY = event.touches[0].clientY;
      }
    },
    handleTouchMove(event) {
      event.preventDefault(); // 阻止默认的滚动行为
      if (this.isPinching) {
        this.touchEndDistance = Math.hypot(
          event.touches[0].clientX - event.touches[1].clientX,
          event.touches[0].clientY - event.touches[1].clientY
        );
        clearTimeout(this.touchTimer);
        this.touchTimer = setTimeout(() => {
          this.handlePinchZoom();
        }, 15);
      } else if (this.isTouching) {
        this.handleTouchMoveSingle(event);
      }
    },
    handleTouchEnd(event) {
      this.isTouching = event.touches.length === 0;
      this.isPinching = false;
      this.touchStartDistance = 0;
      this.touchEndDistance = 0;
    },
    handlePinchZoom() {
      const distanceChange = Math.abs(this.touchEndDistance - this.touchStartDistance);
      if (distanceChange > this.threshold) {
        const scaleFactorChange = this.touchEndDistance / this.touchStartDistance;
        this.scaleFactor *= scaleFactorChange;
        this.scaleFactor = Math.max(0.1, Math.min(5, this.scaleFactor));
        this.touchStartDistance = this.touchEndDistance;
      }
    },
    handleTouchMoveSingle(event) {
      if (!this.isPinching) {
        const touchMoveX = event.touches[0].clientX;
        const touchMoveY = event.touches[0].clientY;
        const offsetX = touchMoveX - this.touchStartX;
        const offsetY = touchMoveY - this.touchStartY;
        this.imagePositionX += offsetX;
        this.imagePositionY += offsetY;
        this.touchStartX = touchMoveX;
        this.touchStartY = touchMoveY;
      }
    },
    // 点击图片显示模型参数
    showModelParameters() {
      this.showModelParametersContainer = true;
    },
    closeModelParameters() {
      this.showModelParametersContainer = false;
    }
  }
};
</script>

<style lang="sass">
.home
  height: 100vh

h4
  font-size: 12px !important
  line-height: 4px !important
  font-family: BoschSans-Light,Helvetica,Arial,sans-serif
  color: #1A1A1A !important
  font-style: normal

.zoom-enter-active, .zoom-leave-active
  transition: transform 0.3s ease


.zoom-enter, .zoom-leave-to 
  transform: scale(0)


.controls
  text-align: center

.param-button
  position: absolute
  padding: 0.5% 1%
  border: 4% solid #a9a9a9
  border-radius: 14%
  cursor: pointer
  background-color: #a9a9a9
  color: #333
  font-size: 3.5vw
  top: 73%
  left: 42%
  z-index: 999

.background-img
  position: relative
  top: 0
  left: 0
  max-width: 100% !important
  height: 100%
  background-size: 100% 110%
  background-position: 0px 0px !important

#oneModel
  z-index: 999999

.model-parameters-container 
  position: fixed
  top: 0
  left: 0
  width: 100vw
  height: 100vh
  background-color: rgba(255, 255, 255, 0)
  padding: 20px
  display: flex
  align-items: center
  justify-content: center
  z-index: 99999

  .close-button 
    position: absolute
    top: 20%
    right: 3%
    width: 21%
    height: 8%
    z-index: 99999999

  img
    max-width: 100%
    max-height: 100%
    margin-bottom: 0px

  ul 
    list-style: none
    padding: 0
    margin: 0
    text-align: left

  li 
    margin-bottom: 0px
  
</style>
